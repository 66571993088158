import React from "react"
import styled from "styled-components"
import { ButtonType } from "../../../../resuable/button"

import Arrow from "../../../../../assets/button/arrow.inline.svg"

const StyledResourceContent = styled.div`
  position: relative;
  width: 100%;
  min-height: 50vh;
  display: flex;
  div.container__left,
  div.container__right {
    flex: 1 1 20%;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 40px;
      h3 {
        margin-bottom: 10px;
        color: #e1e1e1;
        font-size: 24px;
      }
    }
  }
  div.container__left {
    align-items: flex-end;
  }
  div.container__main {
    flex: 1 1 60%;
    max-width: 700px;
    padding: 0px 20px;
    .description-title {
      font-size: 32px;
    }
  }
  div.container__right {
    position: sticky;
    top: 0px;
    align-items: flex-start;
    div.categories {
      .category {
        margin-right: 10px;
        padding: 3px 6px;
        background: #dfdfdf;
        color: #222;
        border-radius: 30px;
        font-size: 10px;
        line-height: 100%;
      }
    }
    div.author {
      .name {
        margin: 0px;
        font-size: 12px;
      }
    }
  }
  div.cta {
    padding: 0px 20px;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    div.container__left,
    div.container__right,
    div.container__main {
      padding: 0 20px;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1 1 100%;
    }
    div.container__main {
      order: 1;
    }
    div.container__left {
      order: 2;
    }
    div.container__right {
      margin-top: 20px;
      position: static;
      order: 3;
    }
    div.cta {
      padding: 0px;
      display: flex;
      justify-content: flex-start;
    }
  }
`

const ResourceContent = ({ children, acf, categories, author }) => {
  console.log(categories)
  return (
    <StyledResourceContent>
      <div className="container__left">
        <div className="cta">
          <a href={acf.link} target="_blank" rel="noopener noreferrer">
            <ButtonType.Base id="a2iresource_link-button">
              Go to Article
              <Arrow />
            </ButtonType.Base>
          </a>
        </div>
      </div>
      <div className="container__main">
        <h2 className="description-title">What is this article about?</h2>
        {children}
      </div>
      <div className="container__right">
        <div className="categories">
          <h3>Posted in</h3>
          {categories !== null ? (
            categories.map((category, index) => (
              <span key={index} className="category">
                {category.name}
              </span>
            ))
          ) : (
            <span className="category">Uncategorized</span>
          )}
        </div>
        <div className="author">
          <h3>Submitted by</h3>
          <p>{author.name}</p>
        </div>
      </div>
    </StyledResourceContent>
  )
}

ResourceContent.defaultProps = {}

export default ResourceContent
