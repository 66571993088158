import React from "react"
import styled from "styled-components"
import Layout from "../../components/resuable/layout/layout"
import { graphql } from "gatsby"
import ResourceHeader from "../../components/page/a2i-resources/resource/resource-header/resource-header"
import SEO from "../../components/resuable/seo/seo"
import ResourceContent from "../../components/page/a2i-resources/resource/resource-content/resource-content"
import Joinus from "../../components/resuable/joinus/joinus"

const StyledA2iResourceTemplate = styled.div``

const A2iResourceTemplate = ({ data, pageContext }) => {
  console.log(pageContext);
  const {
    title,
    content,
    date,
    categories,
    featured_media,
    author,
    acf,
    yoast_meta
  } = data.wordpressWpA2IResources;
  return (
    <Layout>
      <StyledA2iResourceTemplate>
        <SEO 
          title={yoast_meta.yoast_wpseo_title}
        />
        <ResourceHeader 
          title={title}
          featured_media={featured_media}
        />
        <ResourceContent
          acf={acf}
          categories={categories}
          author={author}
        >
          <div 
            dangerouslySetInnerHTML={{ __html: content }}
            className="description"
          />
        </ResourceContent>
        <Joinus />
      </StyledA2iResourceTemplate>
    </Layout>
  )
}

A2iResourceTemplate.defaultProps = {}

export default A2iResourceTemplate

export const query = graphql`
  query specificA2iResource($slug: String!) {
    wordpressWpA2IResources(slug: {eq: $slug}) {
      title
      content
      date
      categories {
        name
      }
      featured_media {
        source_url
        localFile {
          relativePath
        }
      }
      author {
        name
      }
      acf {
        link
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
