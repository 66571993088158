import React from 'react'
import styled from 'styled-components'
import Hero from '../../../../resuable/hero/hero'
import { placeholderImage } from '../../../../../utils/helpers/generator'
import parse from 'html-react-parser';

const StyledResourceHeader = styled.div`
  width: 100%;
  .container__hero {
    height: 60vh;
    margin-bottom: 60px;
    background-image: url(${props => props.featured_media});
    background-color: #8256EF;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: multiply;
    h1 {
      text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.35);
    }
  }
  @media (max-width: 768px) {
    .container__hero {
      margin-bottom: 30px;
    }
  }
`

const ResourceHeader = ({
  title,
  featured_media,
  ...props
}) => {
  console.log(featured_media);
  return (
    <StyledResourceHeader
      featured_media={featured_media !== null ? featured_media.source_url : placeholderImage(400, 400)}
    >
      <Hero 
        title={parse(title)}
        className="container__hero"
      />
    </StyledResourceHeader>
  )
}

ResourceHeader.defaultProps = {
  title: 'A2i Resource'
}

export default ResourceHeader